import React, { useState } from "react";
import { Button, Grid, Typography, Box, Radio, FormControlLabel, FormControl, RadioGroup } from "@mui/material";
import { getLabel } from "../ComplexityManagementTool/ComplexityCriteriaList";

export default function CardsForCategoryOfComplexity({ giveEstimate, categoriesOfComplexity }) {
  const [selectedValues, setSelectedValues] = useState({});

  const handleRadioChange = (event, categoryId) => {
    setSelectedValues({
      ...selectedValues,
      [categoryId]: event.target.value,
    });
  };

  const handleSubmit = () => {
    let closestFibonacciNumber = closestFibonacci(selectedValues);
    giveEstimate(closestFibonacciNumber);
  };

  function closestFibonacci(selectedValues) {
    const sum = sumSelectedValues(selectedValues);
    let fib1 = 0, fib2 = 1;
    let fibs = [fib1, fib2];

    while (fib2 < sum) {
      const nextFib = fib1 + fib2;
      fibs.push(nextFib);
      fib1 = fib2;
      fib2 = nextFib;
    }

    let closestFib = fibs[0];
    let minDiff = Math.abs(sum - fibs[0]);

    for (let i = 1; i < fibs.length; i++) {
      const diff = Math.abs(sum - fibs[i]);
      if (diff < minDiff) {
        minDiff = diff;
        closestFib = fibs[i];
      }
    }

    return closestFib;
  }

  function sumSelectedValues(selectedValues) {
    let sum = 0;
    for (const key in selectedValues) {
      if (selectedValues.hasOwnProperty(key)) {
        sum += parseFloat(selectedValues[key]);
      }
    }
    return sum;
  }

  return (
      <Grid container sx={{ ml: 2, mt: { xs: 1, md: 10 } }}>
        {categoriesOfComplexity.map((row) => {
          const label = getLabel(row.name);
          return (
              <Grid item sx={{ mx: 1, my: 1, mr: 8, }} key={row.id}>
                <Box
                    sx={{
                      minWidth: "150px",
                      minHeight: "97px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "4px",
                      cursor: "pointer",
                      border: "2px solid",
                      borderColor: "secondary.dark",
                      boxShadow: 3,
                      "&:hover": {
                        backgroundColor: "secondary.dark",
                        boxShadow: 6,
                      },
                    }}
                >
                  <FormControl component="fieldset">
                    <Typography variant="h5" sx={{ fontWeight: 300 }} color="success">
                      {label && (
                          <Box
                              sx={{
                                display: "inline-block",
                                px: 1,
                                py: 0.5,
                                bgcolor: label.color,
                                color: "white",
                                borderRadius: 1,
                                fontSize: 12,
                                fontWeight: "bold",
                                textTransform: "uppercase",
                                mr: 1,
                              }}
                          >
                            {label.text}
                          </Box>
                      )}
                      {row.name}
                    </Typography>
                    <RadioGroup
                        aria-label="complexity"
                        name={`radio-buttons-${row.value}`}
                        defaultValue="0"
                        value={selectedValues[row.id] || ""}
                        onChange={(event) => handleRadioChange(event, row.id)}
                    >
                      <FormControlLabel
                          value="0"
                          control={
                            <Radio
                                sx={{
                                  color: "success.main",
                                  "&.Mui-checked": { color: "success.main" },
                                }}
                            />
                          }
                          label="None (0)"
                      />
                      <FormControlLabel
                          value="1"
                          control={
                            <Radio
                                sx={{
                                  color: "success.main",
                                  "&.Mui-checked": { color: "success.main" },
                                }}
                            />
                          }
                          label="Small (1)"
                      />
                      <FormControlLabel
                          value="2"
                          control={
                            <Radio
                                sx={{
                                  color: "success.main",
                                  "&.Mui-checked": { color: "success.main" },
                                }}
                            />
                          }
                          label="Low (2)"
                      />
                      <FormControlLabel
                          value="3"
                          control={
                            <Radio
                                sx={{
                                  color: "success.main",
                                  "&.Mui-checked": { color: "success.main" },
                                }}
                            />
                          }
                          label="Medium (3)"
                      />
                      <FormControlLabel
                          value="4"
                          control={
                            <Radio
                                sx={{
                                  color: "success.main",
                                  "&.Mui-checked": { color: "success.main" },
                                }}
                            />
                          }
                          label="High (4)"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
          );
        })}

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button variant="contained" color="success" onClick={handleSubmit}>
            Submit Estimate
          </Button>
        </Grid>
      </Grid>
  );
}