import React from "react";

import { Paper, Typography, Button, useTheme } from "@mui/material";

import RoundParticipantList from "../genericTool/RoundParticipantList";
import EstimateService from "../../service/EstimateService";

import { isParticipantTheScrumMaster } from "../../Utils/Utils";
import CardsForCategoryOfComplexity from "./CardsForComplexityManagementTool";
import ComplexityBuckets from "../ComplexityBuckets/ComplexityBuckets";


const EstimationRoundForComplexityManagementTool = ({
  currentRound,
  currentParticipantId,
  deleteParticipant,
  giveEstimate,
  scaleValue,
  acceptEstimate,
  categoriesOfComplexity,
}) => {
  const { item, participantEstimateList } = currentRound;
  const currentParticipant =
    participantEstimateList &&
    participantEstimateList.filter(
      (p) => p.participant.id === currentParticipantId
    );
  const currentEstimate = currentParticipant && currentParticipant[0]?.estimate;
  const currentParticipantRole =
    currentParticipant && currentParticipant[0]?.participant.role;
  const isConsensus = EstimateService.isConsensus(currentRound);
  const theme = useTheme();

  return (
    <Paper>
      {item && (
        <>
            <div
                style={{
                    backgroundColor: theme.palette.background.light,
                    paddingBottom: 3,
                }}
            >

                <Typography variant="h5" sx={{p: {xs: 1, md: 2}}}>
                    Currently estimating item : {item.name}
                </Typography>

                {currentParticipant.length > 0 ? (

                    <CardsForCategoryOfComplexity
                        giveEstimate={giveEstimate}
                        categoriesOfComplexity={categoriesOfComplexity}
                    />

                ) : (
                    <></>
                )}


                {currentParticipant.length > 0 ? (
                    <ComplexityBuckets
                        scaleValue={scaleValue}
                        selectedEstimate={currentEstimate}
                    />
                ) : (
                    <></>
                )}

                <RoundParticipantList
                    participants={participantEstimateList}
                    currentParticipantId={currentParticipantId}
                    currentParticipantRole={currentParticipantRole}
                    deleteParticipant={(participantId) =>
                        deleteParticipant(participantId)
                    }
                />


            </div>
        </>
      )}
        {!currentParticipant && (
            <Typography variant="h5" sx={{p: 2}}>
                No active estimate round
            </Typography>
        )}

        {isConsensus && (
            <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.palette.background.light,
          }}
        >
          <h2 style={{ color: "green" }}> CONSENSUS! </h2>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          paddingTop: "15px",
          paddingBottom: "15px",
        }}
      >
        {isConsensus &&
          (isParticipantTheScrumMaster(currentParticipantRole) ? (
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                acceptEstimate();
              }}
            >
              Accept estimate
            </Button>
          ) : (
            <span>Wait for the Scrum Master to confirm the estimate</span>
          ))}
      </div>

    </Paper>
  );
};

export default EstimationRoundForComplexityManagementTool;
