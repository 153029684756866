import React from "react";
import { IconButton, ListItem, List, Typography, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ListCard from "../ListCard/ListCard";
import DialogConfirm from "../DialogConfirm/DialogConfirm";
import { isParticipantTheScrumMaster } from "../../Utils/Utils";

// src/Components/ComplexityManagementTool/ComplexityCriteriaList.jsx
export const getLabel = (name) => {
    const labelMapping = {
        "Staff quantity (team size)": { text: "Size", color: "blue" },
        "Number of stakeholder organizations (subcontractors, customers, partners, investors, users…)": { text: "Size", color: "blue" },
        "Size of capital investment (budget), including resources": { text: "Size", color: "blue" },
        "Number of deliverables": { text: "Size", color: "blue" },
        "Effort (man-days)": { text: "Size", color: "blue" },
        "Duration of the project": { text: "Size", color: "blue" },
        "Number of business areas involved": { text: "Size", color: "blue" },
        "Number of function points": { text: "Size", color: "blue" },
        "Reusability - application developed to meet one or many user’s needs": { text: "Variety", color: "green" },
        "Geographic distribution of the project team (collaborating frequently)": { text: "Variety", color: "green" },
        "Variety of the interests of the stakeholders": { text: "Variety", color: "green" },
        "Variety of information systems to be combined (number of application types)": { text: "Variety", color: "green" },
        "Variety of skills needed": { text: "Variety", color: "green" },
        "Variety of interdependencies": { text: "Variety", color: "green" },
        "Competing objectives": { text: "Variety", color: "green" },
        "Uncertainty and stability of the objectives and requirements": { text: "Variety", color: "green" },
        "Availability of people, material and of any resources due to scarcity of supply on the market or in the organization": { text: "Interdependencies", color: "red" },
        "Specifications interdependence": { text: "Interdependencies", color: "red" },
        "Interdependence between the components of the product": { text: "Interdependencies", color: "red" },
        "Uncertainty of the project plan - level of detail and expected stability": { text: "Interdependencies", color: "red" },
        "Uncertainty and stability of the methods (clear project management methodology, clear software development methodology, risk management, communication, etc.)": { text: "Interdependencies", color: "red" },
        "Unknown and/or unstable legal and regulatory environment": { text: "Context", color: "purple" },
        "Cultural configuration and variety": { text: "Interdependencies/Context", color: "orange" },
        "Environment organizational complexity (networked environment)": { text: "Interdependencies/Context", color: "orange" },
        "Environment technological complexity (networked environment)": { text: "Interdependencies/Context", color: "orange" },
        "Knowledge in the organization - organizational (business and industry; e.g. new business or a new type of customer)": { text: "Interdependencies/Context", color: "orange" },
        "Knowledge in the organization - technical (technology, infrastructure, external interfaces, development platform, tools...)": { text: "Interdependencies/Context", color: "orange" },
        "Level of change imposed by the project on its environment": { text: "Interdependencies/Context", color: "orange" },
    };

    return labelMapping[name] || null;
};


const ComplexityCriteriaList = ({
                                    categoriesOfComplexity,
                                    deleteCategoryOfComplexity,
                                    currentParticipantId,
                                    participants,
                                }) => {
    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
    const [selectedCategoryOfComplexityToDelete, setSelectedCategoryOfComplexityToDelete] = React.useState();

    const currentParticipant = participants && participants.filter((p) => p.id === currentParticipantId);
    const currentParticipantRole = currentParticipant && currentParticipant[0]?.role;

    const handleDeleteCategoryOfComplexity = (id) => {
        deleteCategoryOfComplexity(id);
        setConfirmDeleteOpen(false);
    };

    return (
        <ListCard title="Complexity Criterias">
            <List>
                {categoriesOfComplexity &&
                    categoriesOfComplexity
                        .filter((s) => s.name) // Filter out empty categories
                        .map((s) => {
                            const label = getLabel(s.name);
                            return (
                                <ListItem key={`${s.id}-${s.name}`} sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
                                    <div style={{ display: "flex", textOverflow: "ellipsis", flex: 1, alignItems: "center" }}>
                                        {isParticipantTheScrumMaster(currentParticipantRole) && (
                                            <IconButton
                                                color="success"
                                                onClick={() => {
                                                    setSelectedCategoryOfComplexityToDelete(s);
                                                    setConfirmDeleteOpen(true);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                        {label && (
                                            <Box
                                                sx={{
                                                    ml: 1,
                                                    px: 1,
                                                    py: 0.5,
                                                    bgcolor: label.color,
                                                    color: "white",
                                                    borderRadius: 1,
                                                    fontSize: 12,
                                                    fontWeight: "bold",
                                                    textTransform: "uppercase",
                                                    mr: 2,
                                                    boxShadow: 3,
                                                }}
                                            >
                                                {label.text}
                                            </Box>
                                        )}
                                        <Typography component="p">{s.name}</Typography>
                                    </div>

                                    <DialogConfirm
                                        title={`Are you sure you want to remove this Category of Complexity: ${selectedCategoryOfComplexityToDelete?.name}?`}
                                        open={confirmDeleteOpen}
                                        onConfirm={() => handleDeleteCategoryOfComplexity(selectedCategoryOfComplexityToDelete?.id)}
                                        onReject={() => {
                                            setConfirmDeleteOpen(false);
                                        }}
                                        confirmText="YES"
                                        rejectText="NO"
                                    />
                                </ListItem>
                            );
                        })}
            </List>
        </ListCard>
    );
};

export default ComplexityCriteriaList;